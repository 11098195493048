import React, { useState } from 'react';

import { Modal, Button } from '@leafwell/components';
import Image from 'next/image';

const SezzleModal: React.FC<{
  totalMedicalCardPrice?: string;
}> = ({ totalMedicalCardPrice }) => {
  const [sezzleInfo, toggleSezzleInfo] = useState<boolean>(false);
  const installment = totalMedicalCardPrice
    ? (parseInt(totalMedicalCardPrice) / 4).toFixed(2)
    : '';
  const installmentMain = installment.split('.')[0];
  const installmentDecimals = installment.split('.')[1];

  return (
    <>
      {totalMedicalCardPrice ? (
        <div className="flex justify-center">
          <p className="text-2xl">
            Start for as low as ${installmentMain}
            <sub className="bottom-0 text-base lg:text-md">
              . {installmentDecimals}
            </sub>
            <button
              className="inline px-1.5"
              onClick={e => {
                e.preventDefault();
                toggleSezzleInfo(true);
              }}
            >
              <Image
                className="inline "
                src="/info-w.svg"
                width={20}
                height={20}
                alt="Sezzle information"
              />
            </button>
          </p>
        </div>
      ) : (
        <div className="flex justify-center items-center py-4">
          <p className="mr-1.5">Get now, pay later with</p>
          <button
            className="flex items-center"
            onClick={e => {
              e.preventDefault();
              toggleSezzleInfo(true);
            }}
          >
            <Image
              className="mr-1.5"
              src="/sezzle_logo.svg"
              width={90}
              height={23}
              alt="Sezzle logo"
            />
            <Image
              src="/info-w.svg"
              width={20}
              height={20}
              alt="Sezzle information"
            />
          </button>
        </div>
      )}
      <Modal
        className="max-w-[340px]"
        open={sezzleInfo}
        showClose={false}
        hasIcon={false}
      >
        <div className="flex flex-col items-center text-center">
          <p className="mb-2.5 text-lg font-medium">
            Sezzle it now. Pay us back later
          </p>
          <p className="mb-8 text-sm">
            Use our financing option at check-out to schedule 4 easy payments –
            typically ${installmentMain}.{installmentDecimals} per installment
            with{' '}
            <Image
              className="inline mr-1.5"
              src="/sezzle_logo.svg"
              width={80}
              height={23}
              alt="Sezzle logo"
            />
          </p>
          <div className="flex justify-center mb-8">
            <div className="text-center">
              <Image
                src="/sezzle-25.svg"
                className="mx-auto"
                width={27}
                height={27}
                alt=""
              />
              <p className="text-lg">25%</p>
              <p className="text-sm">today</p>
            </div>
            <hr className="w-6 mt-3 border-black" />
            <div className="text-center">
              <Image
                src="/sezzle-50.svg"
                className="mx-auto"
                width={27}
                height={27}
                alt=""
              />
              <p className="text-lg">25%</p>
              <p className="text-sm">week 2</p>
            </div>
            <hr className="w-6 mt-3 border-black" />
            <div className="text-center">
              <Image
                src="/sezzle-75.svg"
                className="mx-auto"
                width={27}
                height={27}
                alt=""
              />
              <p className="text-lg">25%</p>
              <p className="text-sm">week 4</p>
            </div>
            <hr className="w-6 mt-3 border-black" />
            <div className="text-center">
              <Image
                src="/sezzle-100.svg"
                className="mx-auto"
                width={27}
                height={27}
                alt=""
              />
              <p className="text-lg">25%</p>
              <p className="text-sm">week 6</p>
            </div>
          </div>
          <p className="mb-5 font-medium">
            Proceed to checkout and select Sezzle
          </p>
          <Button
            label="Close"
            variant="secondary"
            rounded={true}
            onClick={() => {
              toggleSezzleInfo(false);
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default SezzleModal;
